/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
// import login from '../components/Login.vue'
const login = () => import(/* webpackChunkName: "login-home-welcome" */ '../components/Login.vue')
// import home from '../components/Home.vue'
const home = () => import(/* webpackChunkName: "login-home-welcome" */ '../components/weChatList.vue')
// import welcome from '../components/Welcome.vue'
const welcome = () => import(/* webpackChunkName: "login-home-welcome" */ '../components/Welcome.vue')

// import user from '../components/user/Users.vue'
const user = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/user/Users.vue')

// import rights from '../components/power/Rights.vue'
const rights = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/power/Rights.vue')
// import roles from '../components/power/Roles.vue'
const roles = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/power/Roles.vue')
// import roles from '../components/power/Roles.vue'
const manager = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/power/Manager.vue')

// import cate from '../components/goods/Cate.vue'
const cate = () => import(/* webpackChunkName: "cate-params" */ '../components/goods/Cate.vue')
// import params from '../components/goods/Params.vue'
const params = () => import(/* webpackChunkName: "cate-params" */ '../components/goods/Params.vue')

// import goods from '../components/goods/List.vue'
const goods = () => import(/* webpackChunkName: "goods-add" */ '../components/goods/List.vue')
// import add from '../components/goods/Add.vue'
const add = () => import(/* webpackChunkName: "goods-add" */ '../components/goods/Add.vue')

// import order from '../components/order/Order.vue'
const order = () => import(/* webpackChunkName: "order-report" */ '../components/order/Order.vue')
// 导入报表组件
// import report from '../components/report/Report.vue'
const report = () => import(/* webpackChunkName: "order-report" */ '../components/report/Report.vue')
const importxls = () => import(/* webpackChunkName: "order-report" */ '../components/excal.vue')
const gzszschool = () => import(/* webpackChunkName: "order-report" */ '../components/gzszschool.vue')
const weChatList = () => import(/* webpackChunkName: "order-report" */ '../components/weChatList.vue')

// 盘资料
const mingPanApi = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/pandata/mingPan.vue')
const liuYearPan = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/pandata/liuYearPan.vue')
// 全部
const starData = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/starData/index.vue')
// 本命
const mingpan = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/starData/mingpan.vue')
// 大运
const dayun = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/starData/dayun.vue')
// 流年
const liuyear = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/starData/liuyear.vue')
// 流月
const liumonth = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/starData/liumonth.vue')
// 四化星
const siHuaXing = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/starData/siHuaXing.vue')
// 合婚资料 age
const marrAge = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/marrAge/age.vue')
const marrStar = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/marrAge/marrStar.vue')
const marrZodiac = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/marrAge/marrZodiac.vue')

const importMarr = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/marrExcal.vue')
const photoList = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/photoList.vue')

const videoList = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/videos/index.vue')

const apiTest = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/apiReadme/apiTest.vue')
//轮播图
const carousel = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/miniSys/carousel.vue')
const carouselAdd = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/miniSys/Add.vue')
//匹配控制
const pipeiCtr = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/miniSys/pipeiCtr.vue')
//隐私与协议
const PrivacyAgreement = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/miniSys/privacyAgreement.vue')
//商务合作
const Cooperation = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/miniSys/cooperation.vue')
//消息通知
const Announcement = () => import(/* webpackChunkName: "user-rights-roles" */ '../components/miniSys/announcement.vue')
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: login },
  { path: '/weChatList', component: weChatList },
  {
    path: '/home',
    component: weChatList,
    redirect: '/weChatList',
    // children: [
    //   { path: '/welcome', component: welcome },
    //   { path: '/users', component: user },
    //   { path: '/rights', component: rights },
    //   { path: '/reports', component: report },
    //   { path: '/roles', component: roles },
    //   { path: '/manager', component: manager },
    //   { path: '/categories', component: cate },
    //   { path: '/params', component: params },
    //   { path: '/goods', component: goods },
    //   { path: '/goods/add', component: add },
    //   { path: '/orders', component: order },
    //   { path: '/starData', component: starData },
    //   { path: '/mingpan', component: mingpan },
    //   { path: '/dayun', component: dayun },
    //   { path: '/liuyear', component: liuyear },
    //   { path: '/liumonth', component: liumonth },
    //   { path: '/importxls', component: importxls },
    //   { path: '/gzszschool', component: gzszschool },
    //   { path: '/mingPanApi', component: mingPanApi },
    //   // { path: '/weChatList', component: weChatList },
      
    //   { path: '/liuyearpan', component: liuYearPan },
    //   { path: '/sihuaxing', component: siHuaXing },
    //   { path: '/marrage', component: marrAge },
    //   { path: '/marrzodiac', component: marrZodiac },
    //   { path: '/marrstar', component: marrStar },
    //   { path: '/importmarr', component: importMarr },
    //   { path: '/photolist', component: videoList },
    //   { path: '/apitest', component: apiTest },
    //   { path: '/carousel', component: carousel },
    //   { path: '/carousel/add', component: carouselAdd },
    //   { path: '/pipeiCtr', component: pipeiCtr },
    //   { path: '/privacyAgreement', component: PrivacyAgreement },
    //   { path: '/cooperation', component: Cooperation },
    //   { path: '/announcement', component: Announcement },
    // ]
  }
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个页面跳转而来
  // next 是一个函数， 表示方形
  //      next() 放行 next('/') 强制跳转
  // 首页直接放行
  if (to.path === '/login') return next()
  // 获取token
  const tokenStr = window.sessionStorage.getItem('token')
  // token不为空，放行
  if (!tokenStr) return next('/login')
  next()
})

export default router
